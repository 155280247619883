import React, { useEffect, useRef, useState } from "react";
import selct_car from "../assets/images/selct_car.svg";
import select_car from "../assets/images/selct_car.svg";
import sedan from "../assets/images/sedan.png";
import etios from "../assets/images/etios.png";
import suv from "../assets/images/suv.png";
import innova from "../assets/images/innova.png";
import { useForm } from "react-hook-form";
import indiaFlag from "../assets/images/indiaflag.png";
import what_img from "../assets/images/whatsapp.png";

import {
  Google,
  GoogleMap,
  useLoadScript,
  StandaloneSearchBox,
  GoogleApiWrapper,
  DistanceMatrixService,
} from "@react-google-maps/api";
import axios from "axios";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { FaRegCheckCircle } from 'react-icons/fa';
import { netbixTelegramChatId, telegramApi, telegramChatId, netbixTelegramApi } from "../config/config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaUser } from "react-icons/fa6";
import { CiUser } from "react-icons/ci";
import { IoMdTime } from "react-icons/io";
import { MdLuggage } from "react-icons/md";
const libraries = ["places"];

const Localrentals = () => {
    const [selectedDate, setSelectedDate] = useState("");
    
    const [finalMobileNumber, setFinalNumber] = useState();
    const [driverBeta, setDriverBeta] = useState(400);
    const [carList, setCarList] = useState(false);
    // const[carImge,setCarImage] =useState();
    const [listCars, setListCars] = useState({ car_amount: 0 });
    const [carReq, setCarReq] = useState(false);
    const [distanceResult, setDistanceResult] = useState({
      org_distance: null,
      org_duration: null,
    });
  
    const [carImages, setCarImages] = useState(null);
  
    const [google, setGoogle] = useState(null);
    const [whatsaappMsg, setWhatsaappMsg] = useState("");
  
    const [popup, setPopup] = useState(false);
  
    const [popupDis, setPopupDis] = useState(0);
    const [popupDur, setPopupDur] = useState("");
    const [popupCar, setPopupCar] = useState("");
    const [popupRate, setPopupRate] = useState(0);
    const [totRate, setTotalRate] = useState(0);
    const [loadingButton, setBoadingButton] = useState(true);
    function openCars() {
      setCarList(!carList);
    }
    const searchBox = useRef(null);
  
    const formRef = useRef(null);
  
    function sendWhatsapp() {
      document.body.classList.remove("dis_scroll");
      setPopup(false);
      window.open(`https://wa.me/+919344020689?text=${whatsaappMsg}`);
    }
  
    const handlePlacesChanged = () => {
      const places = searchBox.current.getPlaces();
      console.log(places);
    };
  
    // const { isLoaded, loadError } = useLoadScript({
    //     googleMapsApiKey: 'AIzaSyCYfVYTCGKRhBNN5v6uIqb3C477IWcVcCU',
    //     libraries,
    // });
  
    const {
      register,
      handleSubmit,
      formState: { errors },
      trigger,
    } = useForm();
  
    useEffect(() => {
      if (window.google) {
        setGoogle(window.google);
      }
    }, []);
  
    const onSubmit = async (data) => {
      var day = selectedDate.getDate();
      console.log(day);
      var month = selectedDate.getMonth() + 1;
      console.log(month);
  
      var year = selectedDate.getFullYear();
      console.log(year);
      var formattedDate = day + '/' + month + '/' + year;
      console.log(formattedDate);
  
      // if (listCars.car_amount === 0) {
      //   setCarReq(true);
      //   return;
      // } else {
      //   setCarReq(false);
      // }
      if (
        // listCars.car_amount !== "0" &&
        data.drop_add !== "" &&
        data.full_name !== "" &&
        data.pick_up_add !== "" &&
        selectedDate !== "" &&
        data.adults !== "" &&
        data.child !== "" &&
  
        data.pickup_time !== ""
      ) {
        setBoadingButton(true);
        console.log("hii");
        if (data.drop_add === data.pick_up_add) {
          return alert("Please select Different Places");
        }
        if (!google) {
          alert("Google Maps API is not loaded yet.");
          return;
        }
        setPopupCar(listCars.text);
  
        const distanceMatrixService = new google.maps.DistanceMatrixService();
  
        const distanceMatrixOptions = {
          origins: [data.pick_up_add],
          destinations: [data.drop_add],
          travelMode: "DRIVING",
        };
  
      
  
        var front_url = "https://zerotaksi.com/";

        const whatsaappMsg = `
        Website Enquiry%0A******* *******%0A%0AYour Booking Details:%0A%0A Name: ${data.full_name},%0A%0A
        Mobile Number: +91${data.mob_number},%0A%0A
        Pickup Location: ${data.pick_up_add},%0A%0A
        Drop Location: ${data.drop_add},%0A%0A
        Adults: ${data.adults},%0A%0A
        Child: ${data.child},%0A%0A
        Travel Date/Time: ${formattedDate} ${data.pickup_time},%0A%0A
        Toll, parking, permit extra %0A%0A
        For any questions please contact +919344020689 %0A%0A
        ${front_url}
        `;
        var message =
        "Website Enquiry\n******* *******\n\nYour Booking Details:\n\nName : " +
        data.full_name +
        " ,\n\nMobile Number  : +91" +
        data.mob_number +
        " ,\n\nPickup Location : " +
        data.pick_up_add +
        " ,\n\nDrop Location : " +
        data.drop_add +
        " ,\n\nAdults : " +
        data.adults +
        " ,\n\nChild : " +
        data.child +
        " ,\n\nPickup Date/Time : " +
        formattedDate +
        " " +
        data.pickup_time +
        " ,\n\nToll, parking, permit extra \n\nFor any questions please contact +919344020689 \n\n" +
        front_url;
 
       
       try{
      
        const response = axios.post(
          `https://api.telegram.org/bot${telegramApi}/sendMessage`,
          {
            chat_id: telegramChatId,
            text: message,
          })
          if (response) {
                     setBoadingButton(true);
                      formRef.current.reset();
                      document.body.classList.add("dis_scroll");
                      setSelectedDate(null);
                      window.open(`https://wa.me/+919344020689?text=${whatsaappMsg}`);        
          }
      }
       catch  (error){
        setBoadingButton(false);
        alert("Please try again");
        console.error("Error sending Telegram message:", error);
       }
       try{
      
        const response = axios.post(
          `https://api.telegram.org/bot${netbixTelegramApi}/sendMessage`,
          {
            chat_id: netbixTelegramChatId,
            text: message,
          })
          if (response) {
                     setBoadingButton(true);
                      formRef.current.reset();
                      document.body.classList.add("dis_scroll");
                      setSelectedDate(null);
                      window.open(`https://wa.me/+919344020689?text=${whatsaappMsg}`);        
          }
      }
       catch  (error){
        setBoadingButton(false);
        alert("Please try again");
        console.error("Error sending Telegram message:", error);
       }
   
      }
    };
    function popUpClose() {
      document.body.classList.remove("dis_scroll");
      setPopup(false);
    }
    const selectCar = (text, car_amount, carDriverBeta) => {
      setListCars({
        text,
        car_amount,
      });
      setDriverBeta(carDriverBeta);
      setCarList(false);
      setCarImages(text);
      // setCarImages((prevImages) => ({
      //   ...prevImages,
      //   [text]: prevImages[text] === defaultImage ? altImage : defaultImage,
      // }));
  
    };
  
  
    return (
      <div className="mt-6 ">
        < form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <div className=" grid grid-cols-1  border mx-3  bg-black">
  
              <input
                type="text"
                className={`w-full border  py-3 px-4 focus:outline-0 ${errors.full_name && "border border-red-500"
                  }`}
                placeholder="Enter your Name"
                name="full_name"
                {...register("full_name", { required: true })}
              />
              <div className=" relative">
                <span
                  className={`top-1/2 left-0 h-full px-2 border border-t-0 text-[16px] absolute -translate-y-1/2 flex gap-x-1 items-center ${errors.mob_number && "border border-red-500"
                    }`}
                >
                  <span>
                    <img src={indiaFlag} className="w-6" />
                  </span>
                  <span className=" text-white">+91</span>
                </span>
                <input
                  type="int"
                  className={`w-full border border-t-0  py-3 px-4 focus:outline-0 pl-20 ${errors.mob_number && "border border-red-500"
                    }`}
                  {...register("mob_number",
                    {
                      required: "Mobile Number is required", minLength: { value: 10, message: "Requied 10 digit" },
                      maxLength: { value: 10, message: "Requried only 10 digit" }
                    })}
                  placeholder="Mobile number"
                  name="mob_number"
                />
              {errors.mob_number ?
                <span className='mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white'>Mobile Number Should Be 10</span> : ''}
  
            </div>
          </div>
          <div className="grid grid-cols-1">
            <div className="p-3 pb-0">
              <StandaloneSearchBox
                onLoad={(ref) => (searchBox.current = ref)}
                onPlacesChanged={handlePlacesChanged}
              >
                <input
                  type="text"
                  className={`w-full py-3 px-4 focus:outline-0 border  ${errors.pick_up_add ? " border-red-400" : "border-b-0"
                    }`}
                  placeholder="Enter your pickup point"
                  name="pick_up_add"
                  {...register("pick_up_add", { required: true })}
                />
              </StandaloneSearchBox>
              {errors.pick_up_add && (
                <span className="mt-2 inline-block  bg-red-500 w-full p-1 px-2 rounded-md text-white">
                  Pick up Address is required.
                </span>
              )}
            </div>
            <div className="p-3 pt-0">
              <StandaloneSearchBox
                onLoad={(ref) => (searchBox.current = ref)}
                onPlacesChanged={handlePlacesChanged}
              >
                <input
                  type="text"
                  className={`w-full py-3 px-4 focus:outline-0 border  ${errors.drop_add &&
                    errors.pick_up_add &&
                    " border-red-400 border-t-0"
                    } ${errors.drop_add && !errors.pick_up_add && " border-red-400 "
                    }`}
                  placeholder="Enter your drop point"
                  name="drop_add"
                  {...register("drop_add", { required: true })}
                />
              </StandaloneSearchBox>
            </div>
            <div className=" grid grid-cols-2 border mx-3  bg-black">
              <div className="px-3">
              <select
                  className={`w-full py-3 px-4 focus:outline-0 ${errors.adults && " border border-red-500"
                    }`}
                  id="adults"
                  name="adults"
                  {...register("adults", { required: true })}
                >
                  <option value="">Adults</option>
  
                  <option value="1">1</option>
  
                  <option value="2">2</option>
  
                  <option value="3">3</option>
  
                  <option value="4">4</option>
  
                  <option value="5">5</option>
  
                  <option value="6">6</option>
  
                  <option value="7">7</option>
  
                  <option value="8">8</option>
                  <option value="Group">Group</option>
  
                </select>
              
  
              </div>
              <div className="px-3">
                <select
                  className={`w-full py-3 px-4 focus:outline-0 ${errors.pickup_time && " border border-red-500"
                    }`}
                  id="child"
                  name="child"
                  {...register("child", { required: true })}
                >
                  <option value="">Child</option>
  
                  <option value="0">0</option>
  
                  <option value="1">1</option>
  
                  <option value="2">2</option>
  
                  <option value="3">3</option>
  
                  <option value="4">4</option>
  
                  <option value="5">5</option>
  
  
                </select>
              </div>
            </div>
            </div>
  
            <div className=" grid grid-cols-2 border mx-3 t-0 bg-black">
              <div className="px-3">
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date()}
                  className={`w-full py-3 px-4 focus:outline-0 ${!selectedDate &&
                    errors.pickup_dates &&
                    "border border-red-500"
                    }`}
                  placeholderText="Travel Date"
                  name="pickup_date"
                  inputMode="none"
                  onFocus={(e) => e.target.blur()} 
                />
  
  
                <input
                  type="hidden"
                  value={selectedDate ? selectedDate : ' '}
                  name="pickup_dates"
                  {...register("pickup_dates", { required: true })}
                />
              </div>
              <div className="px-3">
                <select
                  className={`w-full py-3 px-4 focus:outline-0 ${errors.pickup_time && " border border-red-500"
                    }`}
                  id="pickup_time"
                  name="pickup_time"
                  {...register("pickup_time", { required: true })}
                >
                  <option value="">Travel Time</option>
  
                  <option value="12:00 AM">12:00 AM</option>
  
                  <option value="12:30 AM">12:30 AM</option>
  
                  <option value="01:00 AM">01:00 AM</option>
  
                  <option value="01:30 AM">01:30 AM</option>
  
                  <option value="02:00 AM">02:00 AM</option>
  
                  <option value="02:30 AM">02:30 AM</option>
  
                  <option value="03:00 AM">03:00 AM</option>
  
                  <option value="03:30 AM">03:30 AM</option>
  
                  <option value="04:00 AM">04:00 AM</option>
  
                  <option value="04:30 AM">04:30 AM</option>
  
                  <option value="05:00 AM">05:00 AM</option>
  
                  <option value="05:30 AM">05:30 AM</option>
  
                  <option value="06:00 AM">06:00 AM</option>
  
                  <option value="06:30 AM">06:30 AM</option>
  
                  <option value="07:00 AM">07:00 AM</option>
  
                  <option value="07:30 AM">07:30 AM</option>
  
                  <option value="08:00 AM">08:00 AM</option>
  
                  <option value="08:30 AM">08:30 AM</option>
  
                  <option value="09:00 AM">09:00 AM</option>
  
                  <option value="09:30 AM">09:30 AM</option>
  
                  <option value="10:00 AM">10:00 AM</option>
  
                  <option value="10:30 AM">10:30 AM</option>
  
                  <option value="11:00 AM">11:00 AM</option>
  
                  <option value="11:30 AM">11:30 AM</option>
  
                  <option value="12:00 PM">12:00 PM</option>
  
                  <option value="12:30 PM">12:30 PM</option>
  
                  <option value="01:00 PM">01:00 PM</option>
  
                  <option value="01:30 PM">01:30 PM</option>
  
                  <option value="02:00 PM">02:00 PM</option>
  
                  <option value="02:30 PM">02:30 PM</option>
  
                  <option value="03:00 PM">03:00 PM</option>
  
                  <option value="03:30 PM">03:30 PM</option>
  
                  <option value="04:00 PM">04:00 PM</option>
  
                  <option value="04:30 PM">04:30 PM</option>
  
                  <option value="05:00 PM">05:00 PM</option>
  
                  <option value="05:30 PM">05:30 PM</option>
  
                  <option value="06:00 PM">06:00 PM</option>
  
                  <option value="06:30 PM">06:30 PM</option>
  
                  <option value="07:00 PM">07:00 PM</option>
  
                  <option value="07:30 PM">07:30 PM</option>
  
                  <option value="08:00 PM">08:00 PM</option>
  
                  <option value="08:30 PM">08:30 PM</option>
  
                  <option value="09:00 PM">09:00 PM</option>
  
                  <option value="09:30 PM">09:30 PM</option>
  
                  <option value="10:00 PM">10:00 PM</option>
  
                  <option value="10:30 PM">10:30 PM</option>
  
                  <option value="11:00 PM">11:00 PM</option>
  
                  <option value="11:30 PM">11:30 PM</option>
                </select>
              </div>
            </div>
  
        
          <div className="text-center mt-5">
            <button className="mt-4py-3 px-6 th-btn uppercase
            "    >BOOK NOW</button>
          
          </div>
        </form>
        {popup ? (
          <div className="fixed top-0 left-0  h-full w-full z-[100]">
            <div className="w-[90%] md:w-[40%] min-h-[200px] bg-gray-100 top-1/2 left-1/2  md:top-[30%] lg:top-1/2 lg:left-[28%] -translate-x-1/2 -translate-y-1/2 absolute p-5">
              <span
                className="fixed top-2 right-2 cursor-pointer"
                onClick={popUpClose}
              >
                <AiOutlineCloseCircle className="text-[20px]" />
              </span>
              <div className="flex flex-col items-center mt-2">
              <FaRegCheckCircle className="text-[50px]" />
              
              <h2 className="text-center py-2 text-lg   ">Thank you for submitting. We will contact you shortly</h2>
              </div>
      
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    );
}

export default Localrentals
import React, { useState } from "react";
import OneWay from "./OneWay";
import Roundtrip from "./Roundtrip";
import Localrentals from "./Localrentals";

const Form = () => {
  const [selectedType, setSelectedType] = useState("oneway"); 

  function changeForms(e) {
    setSelectedType(e.target.value); 
  }

  return (
    <div className="">
      <div className="container mx-auto">
        <h3 className="mt-4 font-bold text-xl lg:text-2xl text-center">
          Request Local / Outstation Taxi{" "}
        </h3>

        <div className="pt-6">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-y-4 justify-items-center py-3 pb-5 bg-white rounded-md items-center">
            <label
              className={`font-semibold hover:text-[#000] py-2 ${
                selectedType === "local" ? "text-white form_shadow" : ""
              } cursor-pointer`}
              htmlFor="local"
              onClick={changeForms}
            >
              Local Taxi
              <input
                type="radio"
                name="type"
                id="local"
                value="local"
                className="w-0 h-0"
                checked={selectedType === "local"}
              />
            </label>

            <label
              className={`font-semibold hover:text-[#000] py-2 ${
                selectedType === "oneway" ? "text-white form_shadow" : ""
              } cursor-pointer`}
              htmlFor="oneway"
              onClick={changeForms}
            >
              One Way
              <input
                type="radio"
                name="type"
                id="oneway"
                value="oneway"
                className="w-0 h-0"
                checked={selectedType === "oneway"}
              />
            </label>

            <label
              className={`font-semibold hover:text-[#000] py-2 ${
                selectedType === "round" ? "text-white form_shadow" : ""
              } cursor-pointer`}
              htmlFor="round"
              onClick={changeForms}
            >
               Round Trip
              <input
                type="radio"
                name="type"
                id="round"
                value="round"
                className="w-0 h-0"
                checked={selectedType === "round"}
              />
            </label>
          </div>
        </div>


        {selectedType === "oneway" && (
          <OneWay tripType={setSelectedType} selectedType={selectedType} />
        )}
        {selectedType === "round" && (
          <Roundtrip tripType={setSelectedType} selectedType={selectedType} />
        )}
        {selectedType === "local" && (
          <Localrentals tripType={setSelectedType} selectedType={selectedType} />
        )}
      </div>
    </div>
  );
};

export default Form;
